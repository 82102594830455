.App {
  text-align: center;
}

.customers {
  border-collapse: collapse;
  width: 98%;
}
div.ex1
{
  height: 650px;
  overflow-y: scroll;
  overflow-x: scroll;
}
div.ex2
{
  height: 580px;
  overflow-y: scroll;
}
div.commentex
{
  height: 600px;
  overflow-y: scroll;
}
.customers td, .customers th {
  border: 1px solid #4545;
}

.customers th {
  padding: 3px 5px;
  text-align: center !important;
  background-color: rgb(241, 234, 232);
}
.my_p
{
  word-break: break-word;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   max-height: 120px; /* fallback */
   -webkit-line-clamp: 6; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.mob_nav
{
  display:none; 
  visibility:hidden; 
}
@media screen and (max-width:767px) 
{
  .mob_nav
  {
    display:block; visibility:visible; 
  }
  .desk_nav 
   {
   display:none; visibility:hidden; 
  } 
}

.posts-content{
  margin-top:20px;    
}
.ui-w-40 {
  width: 40px !important;
  height: auto;
}
.default-style .ui-bordered {
  border: 1px solid rgba(24,28,33,0.06);
}
.ui-bg-cover {
  background-color: transparent;
  background-position: center center;
  background-size: cover;
}
.ui-rect {
  padding-top: 50% !important;
}
.ui-rect, .ui-rect-30, .ui-rect-60, .ui-rect-67, .ui-rect-75 {
  position: relative !important;
  display: block !important;
  padding-top: 100% !important;
  width: 100% !important;
}
.d-flex, .d-inline-flex, .media, .media>:not(.media-body), .jumbotron, .card {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.bg-dark {
  background-color: rgba(24,28,33,0.9) !important;
}
.card-footer, .card hr {
  border-color: rgba(24,28,33,0.06);
}
.ui-rect-content {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}
.default-style .ui-bordered {
  border: 1px solid rgba(24,28,33,0.06);
}

.text {
   overflow: hidden;
   line-height: 22px;     /* fallback */
   max-height: 70px;      /* fallback */
   -webkit-line-clamp: 4; /* number of lines to show */
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
}
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');

ul {
	list-style: none;
}


.chatbox {
  width: 100%;
  height: 500px;
  margin: 0 20px 0 0;
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
  display: flex;
  flex-flow: column;
  border-radius: 10px 10px 0 0;
  bottom: 0;
  transition: .1s ease-out;
}

.what-chat
{
  background-color: #F7F7F7 !important;
}
.chat-messages {
  border-top: 1px solid rgba(0, 0, 0, .05);
  padding: 10px;
  overflow: auto;
  display: flex;
  background-color: rgb(243, 240, 240);
  flex-flow: row wrap;
  align-content: flex-start;
  flex: 1;
}

.message-box-holder {
  width: 100%;
  margin: 0 0 5px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.message-sender {
  font-size: 12px;
  margin: 0 0 15px;
  color: #30649c;
  align-self: flex-start;
}

.message-sender a, .message-sender a:link, .message-sender a:visited, .chat-partner-name a, .chat-partner-name a:link, .chat-partner-name a:visited {
  color: #30649c;
  text-decoration: none;
}

.message-box {
  padding: 6px 10px;
  border-radius: 0 0 6px 0;
  position: relative;
  background: rgba(134, 238, 148, 0.418);
  border: 2px solid rgba(100, 170, 0, .1);
  color: #140f0f;
  font-size: 15px;
}

.message-box:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid rgba(100, 170, 0, .2);
  border-right: none;
  top: -22px;
  right: -2px;;
}
.message-box1 {
  padding: 6px 10px;
  border-radius: 6px 0 6px 0;
  position: relative;
  background: rgba(134, 238, 148, 0.418);
  border: 2px solid rgba(100, 170, 0, .1);
  color: black;
  font-size: 15px;
}


.message-partner {
  background: rgba(132, 229, 247, 0.377);;
  border: 2px solid rgba(132, 229, 247, 0.377);;
  align-self: flex-start;
}

.message-partner1 {
  background: rgba(132, 229, 247, 0.377);
  border: 2px solid rgba(132, 229, 247, 0.377);;
  align-self: flex-start;
}


.message-partner1:after {
  border-bottom: 10px solid rgba(0, 114, 135, .2);
  border-left: none;
}
.message-partner:after {
  right: auto;
  bottom: auto;
  top: -22px;
  left: -1px;
  border: 10px solid transparent;
  border-bottom: 10px solid rgba(15, 114, 135, .2);
  border-left: none;
}

.chat-input-holder {
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.chat-input {
  resize: none;
  padding: 5px 10px;
  height: 40px;
  font-family: 'Lato', sans-serif;
	font-size: 14px;
  color: #999999;
  flex: 1;
  border: none;
  background: rgba(0, 0, 0, .05);
   border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.chat-input:focus, .message-send:focus {
  outline: none;
}

.message-send::-moz-focus-inner {
	border:0;
	padding:0;
}

.message-send {
  -webkit-appearance: none;
  background: #9cc900;
  background: -moz-linear-gradient(180deg, #00d8ff, #00b5d6);
  background: -webkit-linear-gradient(180deg, #00d8ff, #00b5d6);
  background: -o-linear-gradient(180deg, #00d8ff, #00b5d6);
  background: -ms-linear-gradient(180deg, #00d8ff, #00b5d6);
  background: linear-gradient(180deg, #00d8ff, #00b5d6);
  color: white;
  font-size: 12px;
  padding: 0 15px;
  border: none;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
.search-box
{
  padding: 10px 40px 0 20px;
}

.header1
{
  background-color: #f8f8f8;
  padding: 10px 10px;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgb(243, 240, 240); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(202, 198, 198); 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f3f0f0; 
}
.post-desc
{
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}
.rounded-circle-self
{
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.time-btn
{
  background-color: rgb(129, 127, 127);
  font-size: 14px;
}
.float-6
{
  background-color: #186ecf;
  float: right;
  font-size: 19px;
}
.card-body label{
  float: left;
}
.innoiceda{
  font-size: 18px;
}
.head
{
  background-color: #f76623;
  text-transform: uppercase;
  font-size: large;
  font-weight: 700;
  color: white;
}
.head1{
  font-size: large;
  background-color: wheat;
}
@media only screen and (max-width: 600px) {
  .float-6 {
    font-size: 14px !important;
  }
  .mob-head
  {
    margin-bottom: 10px;
  }
  .customers input
  {
    width: 120px !important;
  }
}